import React, { useEffect, useState } from 'react';
import { Box, Container, Stack, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Tag1 from '../image/cargoTag/tag1.png';
import Tag2 from '../image/cargoTag/tag2.png';
import Tag3 from '../image/cargoTag/tag3.png';
import Tag4 from '../image/cargoTag/tag4.png';

const ContainerTag = () => {
    const { t, i18n } = useTranslation();
    const [_s_homeTitle1, set_s_homeTitle1] = useState('');
    const [_s_homeTitle2, set_s_homeTitle2] = useState('');

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/downloads/CargoTagV2.1.pdf`;
        link.download = 'CargoTagV2.1.pdf'; // 다운로드될 파일명
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const homeTitle = t('cargoTag');
        const currenLanguage = i18n.language;
    
        useEffect(() => {
            //한글일 때
            if (currenLanguage === 'kr') {
                const text1 = homeTitle.substring(0, 25);
                const text2 = homeTitle.substring(24, 100);
                set_s_homeTitle1(text1);
                set_s_homeTitle2(text2);
            } else {
                //영어어일 때
                const text1 = homeTitle.substring(0, 24);
                const text2 = homeTitle.substring(24, 100);
                set_s_homeTitle1(text1);
                set_s_homeTitle2(text2);
            }
        }, [currenLanguage, homeTitle]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh',
            }}
                >
            <div>
                <Typography variant='h2' fontWeight='bold' align='center'>
                    {_s_homeTitle1}
                </Typography>
                <Typography variant='h4' fontWeight='bold' align='center' margin='60px'>
                    {_s_homeTitle2}
                </Typography>
                <Box sx={{ 
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginRight: '100px'
                        }}>
                    <Button
                        onClick={handleDownload}
                        style={{
                                    padding: '10px 20px',
                                    backgroundColor: '#1976d2',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '4px',
                                    cursor: 'pointer'
                                }}
                    >
                        Download Brochure
                    </Button>
                </Box>

                <Box margin='70px'>
                    <Stack direction="row" spacing={2}
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 4
                    }}
                    >
                        <img 
                            src={Tag1}
                            alt="Tag1"
                            style={{ width: '360px', height: '260px' }}
                        />
                        <img 
                            src={Tag2}
                            alt="Tag2"
                            style={{ width: '360px', height: '260px' }}
                        />
                        <img 
                            src={Tag3}
                            alt="Tag3"
                            style={{ width: '360px', height: '260px' }}
                        />
                        <img 
                            src={Tag4}
                            alt="Tag4"
                            style={{ width: '360px', height: '260px' }}
                        />
                    </Stack>
                </Box>
            </div>
        </Box>
    );
};

    
export default ContainerTag;

