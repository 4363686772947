import { Box, Container, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import lksysLogo from '../lksys-logo.png';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

const AppFooter = () => {
    const { t, i18n } = useTranslation();

    return (
        <Typography
            component='footer'
            sx={{ display: 'flex', bgcolor: '#fff5f8' }}
        >
            <Container maxWidth='xl' sx={{ py: 2 }}>
                <Box>
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        <Stack spacing={1}>
                            <Stack direction='row' spacing={1}>
                                <Typography>{t('footer.address')}</Typography>
                                <Divider
                                    orientation='vertical'
                                    variant='middle'
                                    flexItem
                                    sx={{
                                        borderColor: 'black',
                                    }}
                                />
                                <Typography>
                                    {t('footer.ceo')} : {t('footer.ceoName')}
                                </Typography>
                            </Stack>
                            <Stack direction='row' spacing={1}>
                                <Typography>
                                    {t('footer.email')} :
                                    William.lee@lksys.co.kr
                                </Typography>
                                <Divider
                                    orientation='vertical'
                                    variant='middle'
                                    flexItem
                                    sx={{
                                        borderColor: 'black',
                                    }}
                                />
                                <Typography>
                                    {t('footer.phone')} :{' '}
                                    {t('footer.phoneNumber')}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Box>
                            <Stack
                                // direction='row'
                                spacing={1}
                                alignItems='end'
                            >
                                <img
                                    src={lksysLogo}
                                    style={{ width: '100px' }}
                                    alt='LKSYS 로고'
                                />
                                <Typography>
                                    Copyright 2025 LKSYS Corp. All rights
                                    reserved
                                </Typography>
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
            </Container>
        </Typography>
    );
};

export default AppFooter;
