import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import AppHeader from './views/AppHeader';
import AppFooter from './views/AppFooter';
import { Box, Container } from '@mui/material';
import './i18n.js';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <HelmetProvider>
            <BrowserRouter>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                    }}
                >
                    <Helmet>
                        <title>LKSys - Terminal Solutions</title>
                        <meta name="description" content="LKSYS는 AVAS, HDMap, Y/T 등 스마트 터미널 솔루션을 제공하는 전문기업입니다." />
                        <meta property="og:title" content="LKSYS - 터미널 솔루션 전문기업" />
                        <meta property="og:description" content="LKSYS는 AVAS, HDMap, Y/T 등 스마트 터미널 솔루션을 제공하는 전문기업입니다." />
                    </Helmet>
                    <AppHeader />
                    <Container maxWidth='xl' sx={{ pt: 20, flex: '1' }}>
                        <App />
                    </Container>
                    <AppFooter />
                </Box>
            </BrowserRouter>
        </HelmetProvider>
    </React.StrictMode>
);

reportWebVitals();
