import React from 'react';
import AppBar from '@mui/material/AppBar';
import { Box, Button, Link, Stack, Toolbar, Typography } from '@mui/material';
import Flag from 'react-world-flags';
import { useNavigate, useLocation } from 'react-router-dom';
import i18n from '../i18n';

const AppHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigate = () => {
        navigate('/');
    };

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
    };

    const isHomePage = location.pathname === '/';

    return (
        <AppBar position='fixed' sx={{ background: '#eda902' }}>
            <Toolbar>
                <Box sx={{ flex: 1 }}>
                    <Typography variant='h5' fontWeight='bold'>
                        LKSYS
                    </Typography>
                </Box>
                <Box>
                    <Stack direction='row' spacing={1} alignItems='center'>
                        {!isHomePage && (
                            <Button
                                variant='contained'
                                size='large'
                                sx={{ height: '40px' }}
                                onClick={handleNavigate}
                            >
                                HOME
                            </Button>
                        )}
                        <Button onClick={() => handleLanguageChange('kr')}>
                            <Flag
                                code='KR'
                                style={{
                                    width: '60px',
                                    height: '40px',
                                    objectFit: 'cover',
                                    borderRadius: '5px',
                                }}
                            />
                        </Button>
                        <Button onClick={() => handleLanguageChange('en')}>
                            <Flag
                                code='US'
                                style={{
                                    width: '60px',
                                    height: '40px',
                                    objectFit: 'cover',
                                    borderRadius: '5px',
                                }}
                            />
                        </Button>
                    </Stack>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default AppHeader;
