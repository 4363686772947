import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import hdMap1 from '../image/hdMap/hdMap1.png';
import hdMap2 from '../image/hdMap/hdMap2.png';
import hdMap3 from '../image/hdMap/hdMap3.png';
import hdMap4 from '../image/hdMap/hdMap4.png';
import hdMap5 from '../image/hdMap/hdMap5.png';
import hdMap6 from '../image/hdMap/hdMap6.png';

const HDMap = () => {
    const { t, i18n } = useTranslation();
    const [_s_homeTitle1, set_s_homeTitle1] = useState('');
    const [_s_homeTitle2, set_s_homeTitle2] = useState('');

    const homeTitle = t('hdMap');
    const currenLanguage = i18n.language;

    useEffect(() => {
        //한글일 때
        if (currenLanguage === 'kr') {
            const text1 = homeTitle.substring(0, 13);
            const text2 = homeTitle.substring(14, 80);
            set_s_homeTitle1(text1);
            set_s_homeTitle2(text2);
        } else {
            //영어어일 때
            const text1 = homeTitle.substring(0, 21);
            const text2 = homeTitle.substring(21, 170);
            set_s_homeTitle1(text1);
            set_s_homeTitle2(text2);
        }
    }, [currenLanguage, homeTitle]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh',
            }}
        >
            <div>
                <Typography variant='h2' fontWeight='bold' align='center'>
                    {_s_homeTitle1}
                </Typography>
                <Typography
                    variant='h4'
                    fontWeight='bold'
                    align='center'
                    margin='60px'
                >
                    {_s_homeTitle2}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginRight: '30px',
                    }}
                ></Box>

                <Box margin='70px'>
                    <Stack
                        direction='row'
                        spacing={2}
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 4,
                        }}
                    >
                        <img
                            src={hdMap1}
                            alt='hdMap1'
                            style={{ width: '185px', height: '140px' }}
                        />
                        <img
                            src={hdMap2}
                            alt='hdMap2'
                            style={{ width: '185px', height: '140px' }}
                        />
                        <img
                            src={hdMap3}
                            alt='hdMap3'
                            style={{ width: '185px', height: '140px' }}
                        />
                        <img
                            src={hdMap4}
                            alt='hdMap4'
                            style={{ width: '185px', height: '140px' }}
                        />
                        <img
                            src={hdMap5}
                            alt='hdMap5'
                            style={{ width: '185px', height: '140px' }}
                        />
                        <img
                            src={hdMap6}
                            alt='hdMap6'
                            style={{ width: '185px', height: '140px' }}
                        />
                    </Stack>
                </Box>
            </div>
        </Box>
    );
};

export default HDMap;
