import React from 'react';
import { Route, Routes } from 'react-router-dom';
import APPHome from './views/APPHome';
import ElectricYT from './views/ElectricYT';
import ContainerTag from './views/ContainerTag';
import ContainerNum from './views/ContainerNum';
import HDMap from './views/HDMap';
import AvasService from './views/AvasService';

const App = () => {
    return (
        <Routes>
            <Route path='/' element={<APPHome />} />
            <Route path='/electricYT' element={<ElectricYT />} />
            <Route path='/ContainerTag' element={<ContainerTag />} />
            <Route path='/avasService' element={<AvasService />} />
            <Route path='/hdMap' element={<HDMap />} />
            <Route path='/containerNum' element={<ContainerNum />} />
        </Routes>
    );
};

export default App;
