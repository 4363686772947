import { Box, Container, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import Electric01 from '../image/home/electric_01.jpg';
import ContainerTag01 from '../image/home/containerTag_01.png';
import AvasLogo from '../image/home/AVAS_Logo.png';
import HdMap01 from '../image/home/hdmap_01.jpg';
import ContainerNum01 from '../image/home/constainerNum_01.png.jpg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const APPHome = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [_s_homeTitle1, set_s_homeTitle1] = useState('');
    const [_s_homeTitle2, set_s_homeTitle2] = useState('');

    const handleNavigate = () => {
        navigate('/electricYT');
    };
    const handleNavigate2 = () => {
        navigate('/ContainerTag');
    };
    const handleNavigate3 = () => {
        navigate('/avasService');
    };
    const handleNavigate4 = () => {
        navigate('/hdMap');
    };
    const handleNavigate5 = () => {
        navigate('/containerNum');
    };

    const homeTitle = t('title');
    const currenLanguage = i18n.language;

    useEffect(() => {
        //한글일 때
        if (currenLanguage === 'kr') {
            const text1 = homeTitle.substring(0, 22);
            const text2 = homeTitle.substring(23, 39);
            set_s_homeTitle1(text1);
            set_s_homeTitle2(text2);
        } else {
            //영어어일 때
            const text1 = homeTitle.substring(0, 42);
            const text2 = homeTitle.substring(43, 75);
            set_s_homeTitle1(text1);
            set_s_homeTitle2(text2);
        }
    }, [currenLanguage, homeTitle]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh',
            }}
        >
            <Stack alignItems='center'>
                <Typography variant='h2' fontWeight='bold'>
                    {_s_homeTitle1}
                </Typography>
                <Typography variant='h2' fontWeight='bold'>
                    {_s_homeTitle2}
                </Typography>
            </Stack>
            <Box sx={{ my: 10 }}>
                <Stack
                    direction='row'
                    spacing={2}
                    justifyContent='space-between'
                >
                    <Box
                        sx={{
                            width: '240px',
                            p: 2,
                            border: '2px solid #eda902',
                            borderRadius: '10px',
                        }}
                        onClick={handleNavigate}
                    >
                        <Stack
                            spacing={1}
                            justifyContent='center'
                            alignItems='center'
                        >
                            <img
                                src={Electric01}
                                alt='electric01'
                                style={{ width: '200px', height: '160px' }}
                            />
                            <Typography variant='h6' fontWeight='bold'>
                                {t('home.category1.title')}
                            </Typography>
                            <Typography>
                                {t('home.category1.subTitle')}
                            </Typography>
                        </Stack>
                    </Box>
                    <Box
                        sx={{
                            width: '235px',
                            p: 1,
                            border: '2px solid #eda902',
                            borderRadius: '10px',
                        }}
                        onClick={handleNavigate2}
                    >
                        <Stack
                            spacing={1}
                            justifyContent='center'
                            alignItems='center'
                        >
                            <img
                                src={ContainerTag01}
                                alt='containerTag01'
                                style={{ width: '200px', height: '160px' }}
                            />
                            <Typography variant='h6' fontWeight='bold'>
                                {t('home.category2.title')}
                            </Typography>
                            <Typography>
                                {t('home.category2.subTitle')}
                            </Typography>
                        </Stack>
                    </Box>
                    <Box
                        sx={{
                            width: '235px',
                            p: 1,
                            border: '2px solid #eda902',
                            borderRadius: '10px',
                        }}
                        onClick={handleNavigate3}
                    >
                        <Stack
                            spacing={1}
                            justifyContent='center'
                            alignItems='center'
                        >
                            <img
                                src={AvasLogo}
                                alt='avasLogo'
                                style={{ width: '170px', height: '160px' }}
                            />
                            <Typography variant='h6' fontWeight='bold'>
                                {t('home.category3.title')}
                            </Typography>
                            <Typography>
                                {t('home.category3.subTitle')}
                            </Typography>
                        </Stack>
                    </Box>
                    <Box
                        sx={{
                            width: '235px',
                            p: 1,
                            border: '2px solid #eda902',
                            borderRadius: '10px',
                        }}
                        onClick={handleNavigate4}
                    >
                        <Stack
                            spacing={1}
                            justifyContent='center'
                            alignItems='center'
                        >
                            <img
                                src={HdMap01}
                                alt='hdMap01'
                                style={{ width: '200px', height: '160px' }}
                            />
                            <Typography variant='h6' fontWeight='bold'>
                                {t('home.category4.title')}
                            </Typography>
                            <Typography>
                                {' '}
                                {t('home.category4.subTitle')}
                            </Typography>
                        </Stack>
                    </Box>
                    <Box
                        sx={{
                            width: '235px',
                            p: 1,
                            border: '2px solid #eda902',
                            borderRadius: '10px',
                        }}
                        onClick={handleNavigate5}
                    >
                        <Stack
                            spacing={1}
                            justifyContent='center'
                            alignItems='center'
                        >
                            <img
                                src={ContainerNum01}
                                alt='containerNum01'
                                style={{ width: '200px', height: '160px' }}
                            />
                            <Typography variant='h6' fontWeight='bold'>
                                {t('home.category5.title')}
                            </Typography>
                            <Typography>
                                {t('home.category5.subTitle')}
                            </Typography>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
};

export default APPHome;
