import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import avasImg1 from '../image/avasImg/avasImg1.png';
import avasImg2 from '../image/avasImg/avasImg2.png';
import avasImg3 from '../image/avasImg/avasImg3.png';

const AvasService = () => {
    const { t, i18n } = useTranslation();
    const [_s_homeTitle1, set_s_homeTitle1] = useState('');
    const [_s_homeTitle2, set_s_homeTitle2] = useState('');

    const homeTitle = t('avas');
    const currenLanguage = i18n.language;
        
    useEffect(() => {
        //한글일 때
        if (currenLanguage === 'kr') {
            const text1 = homeTitle.substring(0, 14);
            const text2 = homeTitle.substring(14, 58);
            set_s_homeTitle1(text1);
            set_s_homeTitle2(text2);
        } else {
            //영어어일 때
            const text1 = homeTitle.substring(0, 20);
            const text2 = homeTitle.substring(20, 104);
            set_s_homeTitle1(text1);
            set_s_homeTitle2(text2);
        }
            }, [currenLanguage, homeTitle]);
    return (
        <Box
            sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh',
            }}
        >
            <div>
                <Typography variant='h2' fontWeight='bold' align='center'>
                    {_s_homeTitle1}
                </Typography>
                <Typography variant='h4' fontWeight='bold' align='center' margin='60px'>
                    {_s_homeTitle2}
                </Typography>
                <Box sx={{ 
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginRight: '30px'
                        }}>
                </Box>

                <Box margin='70px'>
                    <Stack direction="row" spacing={2}
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 4
                    }}
                    >
                        <img 
                            src={avasImg1}
                            alt="avasImg1"
                            style={{ width: '370px', height: '260px' }}
                        />
                        <img 
                            src={avasImg2}
                            alt="avasImg2"
                            style={{ width: '370px', height: '260px' }}
                        />
                        <img 
                            src={avasImg3}
                            alt="avasImg3"
                            style={{ width: '370px', height: '260px' }}
                        />
                    </Stack>
                </Box>
            </div>
        </Box>
    );
};

export default AvasService;
