import React, { useEffect, useState } from 'react';
import { Box, Container, Stack, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import contNum1 from '../image/contNum/contNum1.png';
import contNum2 from '../image/contNum/contNum2.png';
import contNum3 from '../image/contNum/contNum3.png';

const ContainerNum = () => {
    
    const { t, i18n } = useTranslation();
    const [_s_homeTitle1, set_s_homeTitle1] = useState('');
    const [_s_homeTitle2, set_s_homeTitle2] = useState('');

    const homeTitle = t('conNumbr');
    const currenLanguage = i18n.language;
        
    useEffect(() => {
        //한글일 때
        if (currenLanguage === 'kr') {
            const text1 = homeTitle.substring(0, 23);
            const text2 = homeTitle.substring(23, 59);
            set_s_homeTitle1(text1);
            set_s_homeTitle2(text2);
        } else {
            //영어어일 때
            const text1 = homeTitle.substring(0, 44);
            const text2 = homeTitle.substring(44, 160);
            set_s_homeTitle1(text1);
            set_s_homeTitle2(text2);
        }
            }, [currenLanguage, homeTitle]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh',
            }}
        >
            <div>
                <Typography variant='h2' fontWeight='bold' align='center'>
                    {_s_homeTitle1}
                </Typography>
                <Typography variant='h4' fontWeight='bold' align='center' margin='60px'>
                    {_s_homeTitle2}
                </Typography>
                <Box sx={{ 
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginRight: '30px'
                        }}>
                </Box>

                <Box margin='70px'>
                    <Stack direction="row" spacing={2}
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 4
                    }}
                    >
                        <img 
                            src={contNum1}
                            alt="contNum1"
                            style={{ width: '370px', height: '260px' }}
                        />
                        <img 
                            src={contNum2}
                            alt="contNum2"
                            style={{ width: '370px', height: '260px' }}
                        />
                        <img 
                            src={contNum3}
                            alt="contNum3"
                            style={{ width: '370px', height: '260px' }}
                        />
                    </Stack>
                </Box>
            </div>
        </Box>
    );
};

export default ContainerNum;
